/* eslint-disable max-len */

import { defineMessages } from 'react-intl'

export default defineMessages({
  backBtn: 'Back to overview',
  teaserText: `Profit from solid, flexible & affordable platform foundation tailored
to a whole industry with over a decade of experience.`,
  btnText: 'Check it out',
  valueTeaserTitle: 'Manage your content easier than ever',
  valueTeaserContent: `With the native support of our freshMS you are able to manage
  your on page marketing activities in a convinient way without the need of confusing CMS backends.
  Benefit from live prices and availabilities for your offers and schedule your promotions as you like.`,
  videoTeaserTitle: 'Our TravelSandbox® at a glance',
  valueTeaserBtnText: 'Learn more about freshMS',
  valueBoxTitle1: 'Delight your users',
  valueBoxTitle2: 'Enrich your business',
  valueBoxTitle3: 'Perks of our technology',
  descriptionTeaserTitle: 'Get a',
  descriptionTeaserContent: `The TravelSandbox® is designed to be your quickstart foundation to guarantee
  a short time to market process. If you want to, it’s of course possible to extend the
  base with custom features and general modifications to get the perfect solution for your business.
  And to be sustainable and flexible it doesn’t matter on which product or content sources you rely.`,
  descriptionTeaserBtn: 'Get in touch now'
})

