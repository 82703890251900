/* eslint-disable max-len */

import { defineMessages } from 'react-intl'

export default defineMessages({
  title: 'Be flexible in terms of product sources',
  contentText: 'We designed the TravelSandbox® with flexibility in mind. This does not stop at the high degree of customizability.We have followed the needs of the market and made it possible to connect product sources of any type.',
  subtitle: 'Product types',
  icon1Label: 'Hotels',
  icon2Label: 'Flights',
  icon3Label: 'Activities',
  subtitle2: 'Possible sources',
  icon4Label: 'Any other'
})


