// @flow
import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import { useIntl } from 'gatsby-plugin-intl'
import messages from './TravelsandboxPage.lang'
import { applyStylingHoc, type StyleOuterProps, type StyleProps } from '@fcse/tsbob/dist/lib/styling'

// components
import ProductTeaser2 from './../ProductTeaser2'
import ValueBox from './../ValueBox'
import Products from '../../../components/Products'
import MightycInfographic from './../InfographicTeasers/TsboInfographic'
import DescriptionTeaser from './../DescriptionTeaser'
import VideoTeaser from './../VideoTeaser'
import ValueTeaser from './../ValueTeaser'

// images
import tsboLogo from '../../../images/logo/travelsandbox.svg'
import freshmsMobile from '../../../images/logo/freshms.svg'
import freshmsDesktop from '../../../images/logo/freshms-color.svg'
import quickstartLogo from '../../../images/logo/quickstart-color.svg'

// styles
import baseStyles from './TravelsandboxPage.scss'

/* eslint-disable max-len */
const videoDesktop = (
  <StaticImage
    src="../../../images/products/tsbo/tsbo-videoteaser-desktop.png"
    alt=""
    objectFit="fill"
    className="imageCover"
  />
)
const descriptionDesktop = (
  <StaticImage
    src="../../../images/products/tsbo/tsbo-teaser4-desktop.png"
    alt=""
    objectFit="contain"
    className="image"
  />
)
const valueDesktop = (
  <StaticImage
    src="../../../images/products/tsbo/tsbo-teaser3-desktop.jpg"
    alt=""
    objectFit="cover"
    className="imageCover"
  />
)
const bgDesktop = (
  <StaticImage
    src="../../../images/products/tsbo/tsbo-bg-desktop.jpg"
    alt=""
    objectFit="cover"
    className="imageCover"
  />
)
const teaser1Desktop = (
  <StaticImage
    src="../../../images/products/tsbo/tsbo-teaser1-desktop.png"
    alt=""
    objectFit="contain"
    className="image"
  />
)
/* eslint-enable max-len */

type Props = {
  ...StyleProps
}

const productValues1 = ['tsboValue1', 'tsboValue2', 'tsboValue3', 'tsboValue4', 'tsboValue5']

const productValues2 = ['tsboValue1a', 'tsboValue2a', 'tsboValue3a', 'tsboValue4a', 'tsboValue5a']

const productValues3 = ['tsboValue1b', 'tsboValue2b', 'tsboValue3b', 'tsboValue4b', 'tsboValue5b']

const descriptionValues = [
  'tsboDescription1',
  'tsboDescription2',
  'tsboDescription3',
  'tsboDescription4',
  'tsboDescription5'
]

const TravelsandboxPage = ({ styles }: Props) => {
  const { formatMessage } = useIntl()

  return (
    <div className={styles.root}>
      <ProductTeaser2
        logo={tsboLogo}
        backBtn={formatMessage(messages.backBtn)}
        teaserText={formatMessage(messages.teaserText)}
        btnText={formatMessage(messages.btnText)}
        imageDesktop={teaser1Desktop}
        bgImageDesktop={bgDesktop}
        hiddenPageTitle="TravelSandbox"
      />
      <ValueBox
        productValues1={productValues1}
        productValues2={productValues2}
        productValues3={productValues3}
        icon1="icon-pen-and-monitor"
        icon2="icon-lightbulb"
        icon3="icon-complex"
        title1={formatMessage(messages.valueBoxTitle1)}
        title2={formatMessage(messages.valueBoxTitle2)}
        title3={formatMessage(messages.valueBoxTitle3)}
      />
      <VideoTeaser
        title={formatMessage(messages.videoTeaserTitle)}
        videoUrl="https://www.youtube.com/embed/IQJvQlYA0W8"
        imageDesktop={videoDesktop}
        type="tsbo"
      />
      <MightycInfographic />
      <ValueTeaser
        title={formatMessage(messages.valueTeaserTitle)}
        contentText={formatMessage(messages.valueTeaserContent)}
        btnText={formatMessage(messages.valueTeaserBtnText)}
        mobileLogo={freshmsMobile}
        desktopLogo={freshmsDesktop}
        imageDesktop={valueDesktop}
        btnLink="/products/freshms/"
      />
      <DescriptionTeaser
        className={styles.descriptionTeaser}
        title={formatMessage(messages.descriptionTeaserTitle)}
        contentText={formatMessage(messages.descriptionTeaserContent)}
        values={descriptionValues}
        btnText={formatMessage(messages.descriptionTeaserBtn)}
        btnLink="/contact/"
        imageDesktop={descriptionDesktop}
        titleImg={quickstartLogo}
      />
      <Products type="tsbo" />
    </div>
  )
}

export default applyStylingHoc(baseStyles)<$Diff<Props, StyleProps> & StyleOuterProps, Function>(TravelsandboxPage)
